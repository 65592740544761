@import 'base.scss';

.uploader {
	display: none;
	height: 0;
	width: 0;
}

.upload {
	&__holder {
		align-items: center;
		background: $white;
		border-radius: 5px;
		border: 1px solid $grey-90;
		cursor: default;
		display: flex;
		font-size: 12px;
		height: $interactive-element-default-height;
		justify-content: space-between;
		margin-top: 8px;
		padding: 0 7px;
	}

	&__file-label {
		align-items: center;
		display: flex;
		gap: 8px;
	}

	&__label {
		color: $blue-primary-35;
		cursor: pointer;
		padding: 7px 0;
	}

	&__actions {
		display: flex;
		gap: 8px;
		align-items: center;
	}

	&__manage {
		cursor: pointer;
	}
}
